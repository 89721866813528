import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class PessoaService extends AuthService {


  /* LISTAR TODOS */
  listar() {
    return axios({
      method: 'get',
      url: this.api_url + 'pessoa',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  listarOcorrencias(codigo) {
    return axios({
      method: 'get',
      url: this.api_url + 'pessoa/ocorrencias/' + codigo,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
  listarOcorrenciasPorCpf(cpf) {
    return axios({
      method: 'get',
      url: this.api_url + 'pessoa/ocorrencias/cpf/' + cpf,
    }).then(res => res.data);
  }
  /* CADASTRAR */
  criar(dados) {
    return axios({
      method: 'post',
      url: this.api_url + 'pessoa',
      withCredentials: true,
      data: dados,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* REMOVER */
  remover(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'pessoa/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* ATUALIZAR */
  atualizar(id, dados) {
    return axios({
      method: 'put',
      url: this.api_url + 'pessoa/' + id,
      withCredentials: true,
      data: dados,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  salvarFoto(id, dados) {
    return axios({
      method: 'put',
      url: this.api_url + 'pessoa/foto/' + id,
      withCredentials: true,
      data: dados,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
        'Content-Type': 'application/json'
      }
    });
  }
  /* RESETAR SENHA */
  resetarSenha(id) {
    return axios({
      method: 'put',
      url: this.api_url + 'pessoa/' + id + "/resetar-password",
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* ALTERAR SENHA */
  alterarSenha(id, dados) {
    return axios({
      method: 'put',
      url: this.api_url + 'pessoa/' + id + '/alterar-password',
      withCredentials: true,
      data: dados,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /*ATIVAR E DESATIVAR USUARIO */
  alterarStatus(id, dados) {
    return axios({
      method: 'put',
      url: this.api_url + 'pessoa/' + id + '/ativo',
      withCredentials: true,
      data: dados,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
        'Content-Type': 'application/json'
      }
    });
  }
}
