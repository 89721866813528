import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class GeneroService extends AuthService {

  /* LISTAR TODOS */
  listar() {
    return axios({
      method: 'get',
      url: this.api_url + 'genero',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
}
