import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class OcorrenciaService extends AuthService {


  /* LISTAR TODOS */
  listar() {
    return axios({
      method: 'get',
      url: this.api_url + 'ocorrencia',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  listarPorPessoa(id) {
    return axios({
      method: 'get',
      url: this.api_url + 'ocorrencia/'+ id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }


  /* CADASTRAR */
  criar(datos) {
    return axios({
      method: 'post',
      url: this.api_url + 'ocorrencia',
      withCredentials: true,
      data: datos,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* REMOVER */
  remover(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'ocorrencia/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* ATUALIZAR */
  atualizar(id, datos) {
    return axios({
      method: 'put',
      url: this.api_url + 'ocorrencia/' + id,
      withCredentials: true,
      data: datos,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
}
