<template>
  <div class="p-grid crud-demo">
    <Toast />
    <div class="p-col-12 p-md-8">
      <div class="card">
        <Toolbar class="p-mb-5">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb-2"
              v-tooltip.top="'CLIQUE PARA CADASTRAR UMA PESSOA.'"
              @click="openPessoa"
            />
          </template>
        </Toolbar>
        <DataTable
          ref="dt"
          :value="pessoas"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedPessoa"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 15, 25]"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} pessoas."
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Pessoas</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>

          <Column field="nome" header="Nome" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nome</span>
              <div class="product-badge">
                {{ slotProps.data.nome }}
              </div>
            </template>
          </Column>
          <Column field="id" header="Chave" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Chave</span>
              <div class="product-badge">
                <i class="pi pi-key"></i> KEY: {{ slotProps.data.id }}
              </div>
            </template>
          </Column>
          <Column field="matricula" header="Matricula" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Matricula</span>
              <div
                v-if="
                  slotProps.data.matricula === null ||
                  slotProps.data.matricula === ''
                "
              >
                SEM MATRÍCULA
              </div>
              <div v-else>{{ slotProps.data.matricula }}</div>
            </template>
          </Column>
          <Column field="cpf" header="CPF" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">CPF</span>
              {{ slotProps.data.cpf }}
            </template>
          </Column>
          <Column field="contato" header="Contato" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Contato</span>
              {{ slotProps.data.contato }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-file-o"
                class="p-button-rounded p-button-info p-button p-mr-2"
                v-tooltip.top="'CLIQUE PARA LISTAR AS OCORRÊNCIAS.'"
                @click="listOcorrencia(slotProps.data.id, slotProps.data.nome)"
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                v-tooltip.top="'CLIQUE PARA EDITAR.'"
                @click="openEditPessoaDialog(slotProps.data)"
              />
              <Button
                icon="pi pi-camera"
                class="p-button-rounded p-button-secondary p-mr-2"
                v-tooltip.top="'CLIQUE PARA FOTOGRAFAR.'"
                @click="openFotoDialog(slotProps.data)"
              />
              <Button
                icon="pi pi-th-large"
                class="p-button-rounded p-button-help p-mr-2"
                v-tooltip.top="'CLIQUE PARA GERAR O QR CODE.'"
                :disabled="
                  verificarDisponibilidadeQrCode(
                    slotProps.data.formaAcesso.nome
                  )
                "
                @click="openQRCODEPessoa(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                v-tooltip.top="'CLIQUE PARA DELETAR.'"
                @click="openDeletePessoaDialog(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!--CADASTRO E ATUALIZAÇÃO DE PESSOA-->
        <Dialog
          v-model:visible="pessoaDialog"
          :style="{ width: '1200px' }"
          :header="titleDialog"
          :modal="true"
          class="p-fluid"
          @hide="hideDialog"
        >
          <br />
          <Card>
            <template #content>
              <Accordion :multiple="true" :activeIndex="[0, 1]">
                <AccordionTab header="Dados Cadastrais">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                      <label for="nome">Nome</label>
                      <InputText
                        id="nome"
                        v-model.trim="pessoa.nome"
                        required="true"
                        placeholder="Digite o nome completo."
                        maxlength="255"
                        autofocus
                        :class="{ 'p-invalid': submitted && !pessoa.nome }"
                      />
                      <small class="p-invalid" v-if="submitted && !pessoa.nome"
                        >Nome é obrigatório.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                      <label for="matricula">Matricula</label>
                      <InputText
                        id="matricula"
                        v-model.trim="pessoa.matricula"
                        placeholder="Digite a matricula."
                        :class="{
                          'p-help': submitted && !pessoa.matricula,
                        }"
                        maxlength="11"
                      />
                      <small
                        class="p-help"
                        v-if="submitted && !pessoa.matricula"
                        >Obrigatório somente para servidores.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                      <label for="cpf">CPF</label>
                      <InputMask
                        id="cpf"
                        v-model.trim="pessoa.cpf"
                        required="true"
                        mask="999.999.999-99"
                        placeholder="Digite o CPF."
                        :class="{ 'p-invalid': submitted && !pessoa.cpf }"
                      />
                      <small class="p-invalid" v-if="submitted && !pessoa.cpf"
                        >CPF é obrigatório.</small
                      >
                    </div>
                  </div>
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                      <label for="hr_entrada">Data de Nascimento</label>
                      <InputText
                        id="hr_entrada"
                        v-model="pessoa.dt_nascimento"
                        type="date"
                        required="true"
                        :class="{
                          'p-invalid': submitted && !pessoa.dt_nascimento,
                        }"
                      />
                      <small
                        class="p-invalid"
                        v-if="submitted && !pessoa.dt_nascimento"
                        >Data de Nascimento é obrigatório.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                      <label for="email">Email</label>
                      <InputText
                        id="email"
                        v-model.trim="pessoa.email"
                        required="true"
                        placeholder="Digite o email."
                        maxlength="255"
                        :class="{
                          'p-help': submitted && !pessoa.email,
                        }"
                      />
                      <small class="p-help" v-if="submitted && !pessoa.email"
                        >Campo opcional.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                      <label for="contato">Contato</label>
                      <InputMask
                        id="contato"
                        v-model.trim="pessoa.contato"
                        mask="(99) 9 9999-9999"
                        required="true"
                        placeholder="Digite o contato."
                        :class="{ 'p-invalid': submitted && !pessoa.contato }"
                      />
                      <small
                        class="p-invalid"
                        v-if="submitted && !pessoa.contato"
                        >Contato é obrigatório.</small
                      >
                    </div>
                  </div>
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                      <label for="genero">Sexo</label>
                      <Dropdown
                        v-model="pessoa.genero.id"
                        :options="genero"
                        optionValue="id"
                        optionLabel="nome"
                        placeholder="Selecione o sexo."
                        :filter="true"
                        :showClear="true"
                        :class="{
                          'p-help': submitted && !pessoa.genero.id,
                        }"
                      >
                      </Dropdown>
                      <small
                        class="p-invalid"
                        v-if="submitted && !pessoa.genero.id"
                        >Sexo é obrigatório.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                      <label for="acesso">Forma de Acesso</label>
                      <Dropdown
                        v-model="pessoa.formaAcesso.id"
                        :options="formaAcesso"
                        optionValue="id"
                        optionLabel="nome"
                        placeholder="Selecione a forma de acesso."
                        :filter="true"
                        :showClear="true"
                        :class="{
                          'p-invalid': submitted && !pessoa.formaAcesso.id,
                        }"
                      >
                      </Dropdown>
                      <small
                        class="p-help"
                        v-if="submitted && !pessoa.genero.id"
                        >Sexo é obrigatório.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-4"></div>
                  </div>
                </AccordionTab>
                <AccordionTab header="Dados Residenciais">
                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                      <label for="cep">CEP</label>
                      <InputMask
                        id="cep"
                        mask="99999-999"
                        v-model.trim="pessoa.endereco.cep"
                        placeholder="Digite o CEP."
                        :class="{ 'p-help': submitted && !pessoa.endereco.cep }"
                        @change="buscarCEP(pessoa.endereco.cep)"
                      />
                      <small
                        class="p-help"
                        v-if="submitted && !pessoa.endereco.cep"
                        >Campo Opcional. Informa-lo pode facilitar o
                        cadastro.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                      <label for="logradouro">Logradouro</label>
                      <InputText
                        id="logradouro"
                        v-model="pessoa.endereco.logradouro"
                        placeholder="Digite o logradouro."
                        :class="{
                          'p-invalid': submitted && !pessoa.endereco.logradouro,
                        }"
                        required="true"
                      />
                      <small
                        class="p-invalid"
                        v-if="submitted && !pessoa.endereco.logradouro"
                        >Endereço é obrigatório.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                      <label for="complemento">Complemento</label>
                      <InputText
                        id="complemento"
                        v-model.trim="pessoa.endereco.complemento"
                        placeholder="Digite o complento."
                        :class="{
                          'p-help': submitted && !pessoa.endereco.complemento,
                        }"
                      />
                      <small
                        class="p-help"
                        v-if="submitted && !pessoa.endereco.complemento"
                        >Campo opcional.</small
                      >
                    </div>
                  </div>

                  <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                      <label for="contato">Bairro</label>
                      <InputText
                        id="contato"
                        v-model.trim="pessoa.endereco.bairro"
                        :class="{
                          'p-invalid': submitted && !pessoa.endereco.bairro,
                        }"
                        required="true"
                        placeholder="Digite o bairro."
                      />
                      <small
                        class="p-invalid"
                        v-if="submitted && !pessoa.endereco.bairro"
                        >Bairro é obrigatório.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                      <label for="localidade">Localidade</label>
                      <InputText
                        id="localidade"
                        v-model="pessoa.endereco.localidade"
                        placeholder="Digite a localidade."
                        :class="{
                          'p-invalid': submitted && !pessoa.endereco.localidade,
                        }"
                        required="true"
                      />
                      <small
                        class="p-invalid"
                        v-if="submitted && !pessoa.endereco.localidade"
                        >Localidade é obrigatório.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-1">
                      <label for="uf">UF</label>
                      <InputText
                        id="uf"
                        v-model.trim="pessoa.endereco.uf"
                        :class="{
                          'p-invalid': submitted && !pessoa.endereco.uf,
                        }"
                        required="true"
                      />
                      <small
                        class="p-invalid"
                        v-if="submitted && !pessoa.endereco.uf"
                        >Uf é obrigatório.</small
                      >
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                      <label for="numero">Número</label>
                      <InputText
                        id="numero"
                        v-model.trim="pessoa.endereco.numero"
                        placeholder="Digite o número."
                        :class="{
                          'p-invalid': submitted && !pessoa.endereco.numero,
                        }"
                        required="true"
                      />
                      <small
                        class="p-invalid"
                        v-if="submitted && !pessoa.endereco.numero"
                        >Número é obrigatório.</small
                      >
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </template>
          </Card>
          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendPessoa"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!--FIM CADASTRO E ATUALIZAÇÃO DE PESSOA-->

        <!--CADASTRO E ATUALIZAR FOTO DA PESSOA-->
        <Dialog
          v-model:visible="pessoaFotoDialog"
          :style="{ width: '1500px' }"
          :header="titleDialog"
          :modal="true"
          @hide="hideDialog"
          class="p-fluid"
        >
          <transition-group name="p-message" tag="div">
            <Message
              v-for="msg of messages"
              :severity="msg.severity"
              :key="msg.id"
              >{{ msg.content }}</Message
            >
          </transition-group>
          <div class="p-fluid p-formgrid p-grid" style="margin-left: px">
            <div class="p-col-12 p-md-6">
              <video id="idVideo" class="img-fluid"></video>
            </div>

            <div class="p-col-12 p-md-6" style="background-color: #ddd">
              <img
                id="idImg"
                :src="srcImg"
                class="img-fluid"
                alt="Foto do Servidor"
                style="margin-left: 40px"
              />
            </div>
          </div>
          <template #footer>
            <div v-if="initUpload">
              <ProgressBar mode="indeterminate" />
            </div>
            <div v-else>
              <Button
                label="Capturar"
                icon="pi pi-camera"
                class="p-button-raised"
                @click="takePicture"
              />
              <Button
                label="Salvar"
                icon="pi pi-check"
                class="p-button-raised p-button-success"
                :disabled="bntSaveFoto"
                @click="onUpload"
              />
              <Button
                label="Cancelar"
                icon="pi pi-times"
                class="p-button-raised p-button-text"
                @click="hideDialog"
              />
            </div>
          </template>
        </Dialog>
        <!--CADASTRO E ATUALIZAR FOTO DA PESSOA-->

        <!-- EXCLUIR PESSOA-->
        <Dialog
          v-model:visible="pessoaDeleteDialog"
          :style="{ width: '450px' }"
          :modal="true"
          @hide="hideDialog"
          header="Leia Atentamente!"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="pessoa"
              ><b>Atenção!</b> Se houverem registros de ocorrências e horários
              para esta pessoa, não será possivel realizar a operação. Tem
              certeza de que deseja excluir a pessoa
              <b>{{ pessoa.nome }} ?</b></span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendDeletePessoa"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM EXCLUIR PESSOA-->

        <!--QRCODE PESSOA-->
        <Dialog
          v-model:visible="pessoaQRDialog"
          :style="{ width: '700px' }"
          :header="titleDialog"
          :modal="true"
          @hide="hideDialog"
        >
          <br />
          <div class="p-d-flex p-ai-center p-jc-center">
            <Card id="cartaoQRCODE" style="width: 22.5em">
              <template #header>
                <div style="padding: 55px 55px 20px 55px">
                  <img
                    src="../../../public/assets/layout/images/sespa/logoEstado.svg"
                    alt="SESPA"
                  />
                </div>
                <div class="p-d-flex p-ai-center p-jc-center">
                  <div style="padding: 10px">
                    <qrcode-vue :value="value" :size="size" level="H" />
                  </div>
                </div>
              </template>

              <template #title
                ><div class="p-text-center">{{ pessoa.nome }}</div>
              </template>
              <template #subtitle>
                <div class="p-text-center">
                  USO PESSOAL E INTRANSFERÍVEL.<br />
                  <small
                    >ESTE CARTÃO E DE USO EXCLUSIVO.<br />
                    PROIBIDO COMPARTILHAMENTO.</small
                  >
                </div>
              </template>
              <template #content>
                <p></p>
              </template>
              <template #footer> </template>
            </Card>
          </div>
          <template #footer>
            <!--
            <Button
              label="Baixar"
              icon="pi pi-print"
              class="p-button-rounded p-button-secondary print"
              @click="imprimirQRCode"
              -->
          </template>
        </Dialog>
        <!--QRCODE PESSOA-->
      </div>
    </div>

    <div class="p-col-12 p-md-4">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:right>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb-2"
              v-tooltip.left="'CLIQUE PARA CADASTRAR UM OCORRÊNCIA.'"
              :disabled="btnNewOcorrencia"
              @click="openOcorrencia"
            />
          </template>
        </Toolbar>
        <DataTable
          ref="dt"
          :value="ocorrencias"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedOcorrencia"
          dataKey="id"
          :paginator="true"
          :rows="5"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} ocorrências."
        >
          <template #header>
            <div class="table-header">{{ selectedPessoa.nome }}</div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>

          <Column field="vinculo" header="N° Vinculo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">N° Vinculo</span>
              <div class="product-badge" v-if="slotProps.data.vinculo === 0">
                SEM VÍNCULO
              </div>
              <div class="product-badge" v-else>
                {{ slotProps.data.vinculo }}
              </div>
            </template>
          </Column>

          <Column field="inicio" header="Inicio" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Inicio</span>
              {{ formatarDatas(slotProps.data.inicio) }}
            </template>
          </Column>

          <Column field="fim" header="Término" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Término</span>
              <div
                class="product-badge"
                v-if="slotProps.data.fim === null || slotProps.data.fim === ''"
              >
                SEM TÉRMINO
              </div>
              <div v-else>{{ formatarDatas(slotProps.data.fim) }}</div>
            </template>
          </Column>

          <Column field="situacao" header="Situação" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Situação</span>
              <div class="product-badge">{{ slotProps.data.situacao }}</div>
            </template>
          </Column>

          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-clock"
                class="p-button-rounded p-button-info p-mr-2"
                v-tooltip.top="'CLIQUE PARA LISTAR HORÁRIOS.'"
                @click="listHorario(slotProps.data.id)"
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                v-tooltip.top="'CLIQUE PARA EDITAR.'"
                @click="openEditOcorrencia(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <!--CADASTRO E ATUALIZAÇÃO DE OCORRÈNCIAS-->
        <Dialog
          v-model:visible="ocorrenciaDialog"
          :style="{ width: '1500px' }"
          :header="titleDialog"
          :modal="true"
          class="p-fluid"
          @hide="hideDialog"
        >
          <br />
          <Card>
            <template #content>
              <hr />
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                  <label for="pessoa">Pessoa</label>
                  <InputText
                    id="pessoa"
                    placeholder="Pessoa"
                    :value="selectedPessoa.nome"
                    v-model.trim="ocorrencia.pessoa.id"
                    disabled="true"
                    class="p-success"
                  />
                </div>

                <div class="p-field p-col-12 p-md-2">
                  <label for="admissao">Data de Admissão</label>
                  <InputText
                    id="admissao"
                    v-model.trim="ocorrencia.inicio"
                    type="date"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !ocorrencia.inicio }"
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !ocorrencia.inicio"
                    >Data de Ingresso é obrigatório.</small
                  >
                </div>

                <div class="p-field p-col-12 p-md-2">
                  <label for="termino">Data de Término</label>
                  <InputText
                    id="termino"
                    v-model.trim="ocorrencia.fim"
                    :disabled="encerrarOcorrencia"
                    type="date"
                    class="p-success"
                  />
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <label for="vinculo">Nº Vínculo</label>
                  <InputNumber
                    id="vinculo"
                    v-model="ocorrencia.vinculo"
                    mode="decimal"
                    :useGrouping="false"
                    class="p-info"
                    placeholder="Digite o número do vinculo."
                  />
                </div>
              </div>
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                  <label for="atuacao">Atuação</label>
                  <Dropdown
                    v-model="ocorrencia.atuacao.id"
                    :options="atuacoes"
                    optionValue="id"
                    optionLabel="sigla"
                    placeholder="Selecione uma atuação."
                    filterPlaceholder="Informe o nome do setor."
                    emptyFilterMessage="Nenhum resultado encontrado."
                    :filter="true"
                    :showClear="true"
                    :class="{
                      'p-invalid': submitted && !ocorrencia.atuacao.id,
                    }"
                  >
                    <template #option="slotProps">
                      {{ slotProps.option.sigla }} |
                      {{ slotProps.option.nome }}
                    </template>
                  </Dropdown>
                  <small
                    class="p-invalid"
                    v-if="submitted && !ocorrencia.atuacao.id"
                    >Atuação é obrigatório.</small
                  >
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <label for="lotacao">Lotação</label>
                  <Dropdown
                    v-model="ocorrencia.lotacao.id"
                    :options="lotacoes"
                    optionValue="id"
                    optionLabel="sigla"
                    placeholder="Selecione uma lotação."
                    filterPlaceholder="Informe o nome do setor."
                    emptyFilterMessage="Nenhum resultado encontrado."
                    class="p-info"
                    :filter="true"
                    :showClear="true"
                  >
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>
                          {{ slotProps.option.sigla }} |
                          {{ slotProps.option.nome }}
                        </div>
                      </div>
                    </template>
                  </Dropdown>
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <label for="descricao">Situação</label>
                  <Dropdown
                    v-model="ocorrencia.situacao"
                    :options="situacoes"
                    optionValue="situacao"
                    optionLabel="situacao"
                    placeholder="Selecione uma situação."
                    :class="{ 'p-invalid': submitted && !ocorrencia.situacao }"
                  >
                  </Dropdown>
                  <small
                    class="p-invalid"
                    v-if="submitted && !ocorrencia.situacao"
                    >Situação é obrigatório.</small
                  >
                </div>
              </div>

              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                  <label for="cargo">Cargo</label>
                  <Dropdown
                    v-model="ocorrencia.cargo.id"
                    :options="cargos"
                    optionValue="id"
                    optionLabel="descricao"
                    placeholder="Selecione um cargo."
                    :filter="true"
                    :showClear="true"
                    class="p-info"
                  >
                  </Dropdown>
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <label for="funcao">Função</label>
                  <Dropdown
                    v-model="ocorrencia.funcao.id"
                    :options="funcoes"
                    optionValue="id"
                    optionLabel="descricao"
                    placeholder="Selecione uma função."
                    :filter="true"
                    :showClear="true"
                    class="p-info"
                  >
                  </Dropdown>
                </div>

                <div class="p-field p-col-12 p-md-4">
                  <label for="categoria">Categoria</label>
                  <Dropdown
                    v-model="ocorrencia.categoria.id"
                    :options="categorias"
                    optionValue="id"
                    optionLabel="descricao"
                    placeholder="Selecione uma categoria."
                    :filter="true"
                    :showClear="true"
                    :class="{
                      'p-invalid': submitted && !ocorrencia.categoria.id,
                    }"
                  >
                  </Dropdown>
                  <small
                    class="p-invalid"
                    v-if="submitted && !ocorrencia.categoria.id"
                    >Categoria é obrigatório.</small
                  >
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </template>
          </Card>

          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendOcorrencia"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!--FIM CADASTRO E ATUALIZAÇÃO DE OCORRÈNCIAS-->
      </div>

      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:right>
            <Button
              label="Novo"
              :disabled="btnNewHorario"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb-2"
              v-tooltip.left="'CLIQUE PARA CADASTRAR UMA JORNADA DE TRABALHO.'"
              @click="openHorario"
            />
          </template>
        </Toolbar>
        <DataTable
          ref="dt"
          :value="horarios"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedHorario"
          dataKey="id"
          :paginator="true"
          :rows="5"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} horários."
        >
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>

          <Column field="hr_entrada" header="Entrada" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Entrada</span>
              {{ slotProps.data.hr_entrada }}
            </template>
          </Column>
          <Column field="hr_saida" header="Saída" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Saída</span>
              {{ slotProps.data.hr_saida }}
            </template>
          </Column>
          <Column field="carga_horaria" header="Carga Horária" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Situação</span>
              {{ slotProps.data.carga_horaria }}
            </template>
          </Column>
          <Column field="fim" header="Situação" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Situação</span>
              <div
                class="product-badge"
                v-if="slotProps.data.fim === null || slotProps.data.fim === ''"
              >
                HÓRARIO ATUAL
              </div>
              <div else>{{ formatarDatas(slotProps.data.fim) }}</div>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                v-tooltip.top="'CLIQUE PARA EDITAR.'"
                @click="openEditHorario(slotProps.data)"
              />
              <Button
                icon="pi pi-exclamation-circle"
                class="p-button-rounded p-button-danger p-mr-2"
                :disabled="verificarEncerrado(slotProps.data.fim)"
                v-tooltip.top="'CLIQUE PARA ENCERRAR JORNADA DE TRABALHO.'"
                @click="openFinalizarHorario(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                v-tooltip.top="'CLIQUE PARA EXCLUIR.'"
                @click="openDeleteHorarioDialog(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <!--CADASTRO DE HORARIOS-->
        <Dialog
          v-model:visible="horarioDialog"
          :style="{ width: '600px' }"
          :header="titleDialog"
          :modal="true"
          class="p-fluid"
          @hide="hideDialog"
        >
          <br />
          <Card>
            <template #content>
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <label for="entrada">Horário de Entrada </label>
                  <InputText
                    id="entrada"
                    v-model.trim="horario.hr_entrada"
                    placeholder="Digite o horário de entrada."
                    type="time"
                    step="1"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !horario.hr_entrada }"
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !horario.hr_entrada"
                    >Data de Ingresso é obrigatório.</small
                  >
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="saida">Horário de Saída</label>
                  <InputText
                    id="saida"
                    v-model.trim="horario.hr_saida"
                    placeholder="Digite o horário de saída."
                    type="time"
                    step="1"
                    required="true"
                    :class="{ 'p-invalid': submitted && !horario.hr_saida }"
                  />
                  <small class="p-invalid" v-if="submitted && !horario.hr_saida"
                    >Data de Ingresso é obrigatório.</small
                  >
                </div>
              </div>
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <label for="ch">Carga Horária (CH) </label>
                  <InputText
                    id="ch"
                    v-model.trim="horario.carga_horaria"
                    required="true"
                    placeholder="Digite a carga horária."
                    :class="{
                      'p-invalid': submitted && !horario.carga_horaria,
                    }"
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !horario.carga_horaria"
                    >Data de Ingresso é obrigatório.</small
                  >
                </div>
                <div class="p-field p-col-12 p-md-6">
                  <label for="descricao">Descrição</label>
                  <InputText
                    id="descricao"
                    v-model.trim="horario.descricao"
                    required="true"
                    placeholder="Digite a descrição."
                  />
                </div>
              </div>
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <label for="dtInicio">Início</label>
                  <InputText
                    id="dtInicio"
                    v-model.trim="horario.inicio"
                    type="date"
                    :class="{
                      'p-invalid': submitted && !horario.inicio,
                    }"
                    required="true"
                  />
                  <small class="p-invalid" v-if="submitted && !horario.inicio"
                    >Data de Ingresso é obrigatório.</small
                  >
                </div>
                <div class="p-field p-col-12 p-md-6">
                  <label for="dtFim">Término</label>
                  <InputText
                    id="dtFim"
                    v-model.trim="horario.fim"
                    type="date"
                    disabled="true"
                  />
                </div>
              </div>
            </template>
          </Card>
          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendHorario"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!--FIM CADASTRO DE HORARIOS-->
        <!-- EXCLUIR HORARIO-->
        <Dialog
          v-model:visible="horarioDeleteDialog"
          :style="{ width: '450px' }"
          :modal="true"
          @hide="hideDialog"
          header="Leia Atentamente!"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="horario"
              ><b>Atenção!</b> Tem certeza de que deseja excluir o horário
              referente a pessoa
              <b>{{ horario.ocorrencia.pessoa.nome }} ?</b></span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendDeleteHorario"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM EXCLUIR HORARIO-->
        <!-- ENCERRAR JORNADA DE TRABALHO -->
        <Dialog
          v-model:visible="horarioFinalizarDialog"
          :header="titleDialog"
          :style="{ width: '450px' }"
          :modal="true"
          @hide="hideDialog"
        >
          <br />
          <!--  CADASTRO DE USUARIO -->
          <div class="p-shadow-4" style="padding: 30px">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <label for="descricao">Descrição</label>
                <InputText
                  v-model="horario.descricao"
                  placeholder="Informe a descrição."
                ></InputText>
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <label for="encerramento">Encerramento </label>
                <InputText type="date" v-model="horario.fim"></InputText>
              </div>
            </div>
          </div>
          <br />
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendHorario"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM ENCERRAR JORNADA DE TRABALHO -->
      </div>
    </div>
  </div>
</template>
<script>
import Moment from "moment";
import axios from "axios";
import QrcodeVue from "qrcode.vue";

import AuthService from "@/service/Auth/AuthService";

import PessoaService from "@/service/Pessoa/PessoaService";
import OcorrenciaService from "@/service/Ocorrencia/OcorrenciaService";
import HorarioService from "@/service/Horario/HorarioService";
import FuncaoService from "@/service/Funcao/FuncaoService";
import CargoService from "@/service/Cargo/CargoService";
import CategoriaService from "@/service/Categoria/CategoriaService";
import SetorService from "@/service/Setor/SetorService";

import GeneroService from "@/service/Genero/GeneroService";
import FormaAcessoService from "@/service/FormaAcesso/FormaAcessoService";

export default {
  data() {
    return {
      /** */
      value: null,
      size: 100,
      /** VARIAVEIS GLOBAIS **/
      submitted: false,
      initUpload: false,
      bntSaveFoto: true,
      btnNewOcorrencia: true,
      btnNewHorario: true,
      titleDialog: null,
      msgDesenvolvedor: null,
      msgUsuario: null,
      messages: [],
      stream: null,
      srcImg: null,
      filters: {},
      genero: null,
      formaAcesso: null,
      /** FIM VARIAVEIS GLOBAIS **/
      /** PESSOA  **/
      pessoaDialog: false,
      pessoaDeleteDialog: false,
      pessoaFotoDialog: false,
      pessoaQRDialog: false,
      pessoas: null,
      selectedPessoa: {
        id: null,
        nome: null,
      },
      pessoa: {
        id: null,
        nome: null,
        matricula: null,
        cpf: null,
        contato: null,
        email: null,
        url_foto: null,
        dt_nascimento: null,
        dt_cadastro: new Date(),
        endereco: {
          id: null,
          cep: null,
          logradouro: null,
          complemento: null,
          bairro: null,
          localidade: null,
          uf: "PA",
          numero: null,
        },
        genero: {
          id: null,
        },
        formaAcesso: {
          id: null,
        },
      },
      /** PESSOA FIM **/
      /** OCORRENCIA FIM **/
      ocorrenciaDialog: false,
      ocorrencias: null,
      selectedOcorrencia: null,
      categorias: null,
      lotacoes: null,
      atuacoes: null,
      cargos: null,
      funcoes: null,
      ocorrencia: {
        id: null,
        vinculo: null,
        inicio: null,
        fim: null,
        situacao: null,
        pessoa: {
          id: null,
        },
        categoria: {
          id: null,
        },
        lotacao: {
          id: null,
        },
        atuacao: {
          id: null,
        },
        cargo: {
          id: null,
        },
        funcao: {
          id: null,
        },
      },
      situacoes: [
        { situacao: "ATIVO" },
        { situacao: "ENCERRADO" },
        { situacao: "CEDIDO ÔNUS DESTINO" },
        { situacao: "CEDIDO ÔNUS ORIGEM" },
        { situacao: "DEMITIDO" },
        { situacao: "FALECIDO" },
        { situacao: "EXONERADO" },
        { situacao: "APOSENTADO" },
        { situacao: "DISTRATADO" },
        { situacao: "PENDENTE" },
      ],
      /** OCORRENCIA FIM **/
      /** HORARIO INICIO **/
      horarios: null,
      selectedHorario: null,
      btnEncerrarHorario: true,
      horarioDialog: false,
      horarioFinalizarDialog: false,
      horarioDeleteDialog: false,
      horario: {
        id: null,
        hr_entrada: null,
        hr_saida: null,
        carga_horaria: null,
        inicio: null,
        fim: null,
        descricao: null,
        ocorrencia: {
          id: null,
        },
      },
      /** HORARIO INICIO **/
    };
  },
  components: {
    QrcodeVue,
  },
  authService: null,
  pessoaService: null,
  ocorrenciaService: null,
  horarioService: null,
  funcaoService: null,
  cargoService: null,
  categoriaService: null,
  setorService: null,
  generoService: null,
  formaAcessoService: null,
  created() {
    this.authService = new AuthService();
    this.pessoaService = new PessoaService();

    this.funcaoService = new FuncaoService();
    this.cargoService = new CargoService();
    this.categoriaService = new CategoriaService();
    this.setorService = new SetorService();

    this.ocorrenciaService = new OcorrenciaService();

    this.horarioService = new HorarioService();

    this.funcaoService = new FuncaoService();
    this.cargoService = new CargoService();
    this.categoriaService = new CategoriaService();
    this.setorService = new SetorService();

    this.generoService = new GeneroService();
    this.formaAcessoService = new FormaAcessoService();

    this.storageRef = this.$firebaseApp.storage();
  },
  mounted() {
    this.authService.checarToken(this.listPessoa());
    this.authService.checarToken(this.listarCategoria());
    this.authService.checarToken(this.listarSetores());
    this.authService.checarToken(this.listarCargo());
    this.authService.checarToken(this.listarFuncao());
    this.authService.checarToken(this.listGenero());
    this.authService.checarToken(this.listAcesso());
  },
  methods: {
    /** FUNÇÕES GENERICAS */
    formatarDatas(date) {
      if (date) {
        return Moment(date).utc().format("DD/MM/YYYY");
      }
      return;
    },
    buscarCEP(cep) {
      if (cep == null || cep == "") {
        this.pessoa.endereco.cep = "";
        this.pessoa.endereco.logradouro = "";
        this.pessoa.endereco.complemento = "";
        this.pessoa.endereco.bairro = "";
        this.pessoa.endereco.localidade = "";
        this.pessoa.endereco.uf = "";
      } else {
        return axios
          .get("https://viacep.com.br/ws/" + cep + "/json/")
          .then((res) => {
            if (res.status === 200 && res.data.erro === true) {
              return;
            } else {
              this.pessoa.endereco.cep = res.data.cep;
              this.pessoa.endereco.logradouro = res.data.logradouro;
              this.pessoa.endereco.complemento = res.data.complemento;
              this.pessoa.endereco.bairro = res.data.bairro;
              this.pessoa.endereco.localidade = res.data.localidade;
              this.pessoa.endereco.uf = res.data.uf;
            }
          });
      }
    },
    hideDialog() {
      this.pessoa = {
        id: null,
        nome: null,
        matricula: null,
        cpf: null,
        contato: null,
        email: null,
        url_foto: null,
        dt_nascimento: null,
        dt_cadastro: new Date(),
        endereco: {
          id: null,
          cep: null,
          logradouro: null,
          complemento: null,
          bairro: null,
          localidade: null,
          uf: "PA",
          numero: null,
        },
        genero: {
          id: null,
        },
        formaAcesso: {
          id: null,
        },
      };
      this.ocorrencia = {
        id: null,
        vinculo: null,
        inicio: null,
        fim: null,
        situacao: null,
        pessoa: {
          id: null,
        },
        categoria: {
          id: null,
        },
        lotacao: {
          id: null,
        },
        atuacao: {
          id: null,
        },
        cargo: {
          id: null,
        },
        funcao: {
          id: null,
        },
      };
      this.horario = {
        id: null,
        hr_entrada: null,
        hr_saida: null,
        inicio: null,
        fim: null,
        carga_horaria: null,
        descricao: null,
        ocorrencia: {
          id: null,
        },
      };
      this.stopVideo();
      this.submitted = false;
      this.bntSaveFoto = true;
      this.srcImg = null;
      this.messages = [];
      this.pessoaDialog = false;
      this.pessoaDeleteDialog = false;
      this.pessoaFotoDialog = false;
      this.ocorrenciaDialog = false;
      this.horarioDialog = false;
      this.horarioDeleteDialog = false;
      this.horarioFinalizarDialog = false;
    },
    listGenero() {
      this.generoService.listar().then((data) => {
        this.genero = data;
      });
    },
    listAcesso() {
      this.formaAcessoService.listar().then((data) => {
        this.formaAcesso = data;
      });
    },
    /** FUNÇÕES GENERICAS FIM */
    /** FUNÇÕES PESSOA */
    openPessoa() {
      this.titleDialog = "Cadastro de Pessoa";
      this.pessoaDialog = true;
    },
    openQRCODEPessoa(pessoa) {
      this.pessoa = pessoa;
      this.titleDialog = "CARTÃO QR CODE | " + this.pessoa.nome;
      this.value = "" + this.pessoa.id + "";

      this.pessoaQRDialog = true;
    },
    openEditPessoaDialog(pessoa) {
      this.pessoa = pessoa;
      this.pessoa.dt_nascimento = this.pessoa.dt_nascimento.replace(
        "T00:00:00.000+00:00",
        ""
      );
      this.titleDialog = this.pessoa.nome;
      this.pessoaDialog = true;
    },
    openFotoDialog(pessoa) {
      this.pessoa = pessoa;
      this.srcImg = this.pessoa.url_foto;
      this.playVideo();
      this.titleDialog = "Fotografia";
      this.pessoaFotoDialog = true;
    },
    openDeletePessoaDialog(pessoa) {
      this.pessoa = pessoa;
      this.pessoaDeleteDialog = true;
    },
    listPessoa() {
      this.pessoaService.listar().then((data) => {
        this.pessoas = data;
      });
    },
    savePessoa() {
      this.submitted = true;
      if (this.pessoa.id == null) {
        this.pessoaService
          .criar(this.pessoa)
          .then((data) => {
            if (data.status === 201) {
              this.listPessoa();
              this.hideDialog();
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Pessoa cadastrada com sucesso!",
                life: 5000,
              });
            }
          })
          .catch((error) => {
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;

            this.msgDesenvolvedor = this.msgDesenvolvedor.replace(
              "br.gov.pa.saude.api.exceptionhandler.NegocioException:",
              ""
            );

            alert(this.msgUsuario + " " + this.msgDesenvolvedor);
          });
      } else {
        this.pessoaService
          .atualizar(this.pessoa.id, this.pessoa)
          .then((data) => {
            if (data.status === 200) {
              this.listPessoa();
              this.hideDialog();
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Pessoa atualizada com sucesso.",
                life: 5000,
              });
            }
          })
          .catch((error) => {
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;

            this.msgDesenvolvedor = this.msgDesenvolvedor.replace(
              "br.gov.pa.saude.api.exceptionhandler.NegocioException:",
              ""
            );

            alert(this.msgUsuario + " " + this.msgDesenvolvedor);
          });
      }
    },
    sendPessoa() {
      this.authService.checarToken(this.savePessoa());
    },
    deletePessoa() {
      this.pessoaService
        .remover(this.pessoa.id)
        .then((data) => {
          if (data.status === 204) {
            this.listPessoa();
            this.hideDialog();
            this.$toast.add({
              severity: "success",
              summary: "Sucesso!",
              detail: "Pessoa deletada com sucesso.",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "erro",
              summary: "Erro!",
              detail: "Erro ao deletar registro.",
              life: 5000,
            });
          }
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Erro!",
            detail: err.response.data[0].mensagemUsuario,
            life: 5000,
          });
          this.hideDialog();
        });
    },
    sendDeletePessoa() {
      this.authService.checarToken(this.deletePessoa());
    },
    /** FUNÇÕES PESSOA FIM */
    /** FUNÇÕES ABAIXO RESPONSAVEL PELA PARTE INSERÇÃO DE IMAGEM NO FIREBASE NO BD **/
    playVideo() {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((stream) => {
          this.stream = stream;
          const video = document.querySelector("#idVideo");
          video.srcObject = this.stream;
          video.play();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    stopVideo() {
      if (this.stream != null) {
        this.stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.stream = null;
      }
    },
    takePicture() {
      let canvas = document.createElement("canvas");
      const video = document.querySelector("#idVideo");
      canvas.setAttribute("height", video.videoHeight);
      canvas.setAttribute("width", video.videoWidth);
      let context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.srcImg = canvas.toDataURL("image/jpeg");
      this.pessoa.url_foto = this.srcImg;
      this.bntSaveFoto = false;
    },
    onUpload() {
      this.initUpload = true;
      this.storageRef
        .ref()
        .child("pessoa/" + this.pessoa.id)
        //  .child(this.servidor.id)
        .putString(this.srcImg, "data_url")
        .then((snapshot) => {
          this.getDownloadURL(snapshot);
        });
    },
    saveFoto(id, url_foto) {
      this.pessoaService
        .salvarFoto(id, url_foto)
        .then((data) => {
          if (data.status === 204) {
            this.initUpload = false;
            this.messages = [
              {
                severity: "success",
                content: "Upload realizado com sucesso! ",
                id: this.count++,
              },
              {
                severity: "success",
                content: "Foto salva com sucesso! ",
                id: this.count++,
              },
            ];
          }
        })
        .catch((error) => {
          this.msgUsuario = error.response.data[0].mensagemUsuario;
          this.msgDesenvolvedor = error.response.data[0].mensagemDesenvolvedor;
        });
    },
    getDownloadURL(snapshot) {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.pessoa.url_foto = downloadURL;
        this.authService.checarToken(
          this.saveFoto(this.pessoa.id, this.pessoa.url_foto)
        );
      });
    },
    /** FIM FUNÇÕES FIREBASE E BD **/
    /** FUNÇÕES OCORRENCIA */
    openOcorrencia() {
      this.ocorrencia.pessoa.id = this.selectedPessoa.id;
      this.titleDialog = "Cadastro de Ocorrência";
      this.encerrarOcorrencia = true;
      this.ocorrenciaDialog = true;
    },
    openEditOcorrencia(ocorrencia) {
      this.ocorrencia = ocorrencia;
      this.encerrarOcorrencia = false;
      this.selectedPessoa = {
        id: this.ocorrencia.pessoa.id,
        nome: this.ocorrencia.pessoa.nome,
      };

      if (this.ocorrencia.funcao === null) {
        this.ocorrencia.funcao = { id: null };
      }
      if (this.ocorrencia.cargo === null) {
        this.ocorrencia.cargo = { id: null };
      }
      if (this.ocorrencia.lotacao === null) {
        this.ocorrencia.lotacao = { id: null };
      }
      this.titleDialog =
        "Ocorrência de Nº " +
        this.ocorrencia.id +
        " | " +
        this.ocorrencia.pessoa.nome;
      this.ocorrenciaDialog = true;
    },
    listarCategoria() {
      this.categoriaService.listar().then((data) => {
        this.categorias = data;
      });
    },
    listarSetores() {
      this.setorService.listar().then((data) => {
        this.lotacoes = data;
        this.atuacoes = data;
      });
    },
    listarCargo() {
      this.cargoService.listar().then((data) => {
        this.cargos = data;
      });
    },
    listarFuncao() {
      this.funcaoService.listar().then((data) => {
        this.funcoes = data;
      });
    },
    listOcorrencia(idPessoa, nomePessoa) {
      /*this.ocorrencia.pessoa.id = idPessoa;*/
      this.selectedPessoa = { id: idPessoa, nome: nomePessoa };
      this.ocorrencia.pessoa.id = this.selectedPessoa.id;
      this.selectedOcorrencia = null;
      this.btnNewOcorrencia = false;
      this.btnNewHorario = true;
      this.ocorrenciaService.listarPorPessoa(idPessoa).then((data) => {
        this.horarios = null;
        this.ocorrencias = data;
      });
    },
    saveOcorrencia() {
      this.submitted = true;
      if (this.ocorrencia.id == null) {
        this.ocorrenciaService
          .criar(this.ocorrencia)
          .then((data) => {
            if (data.status === 201) {
              this.listOcorrencia(this.ocorrencia.pessoa.id);
              this.hideDialog();
              console.log(this.selectedPessoa);
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Ocorrência cadastrada com sucesso!",
                life: 5000,
              });
            }
          })
          .catch((error) => {
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;

            this.msgDesenvolvedor = this.msgDesenvolvedor.replace(
              "br.gov.pa.saude.api.exceptionhandler.NegocioException:",
              ""
            );

            alert(this.msgUsuario + ". " + this.msgDesenvolvedor);
          });
      } else {
        this.ocorrenciaService
          .atualizar(this.ocorrencia.id, this.ocorrencia)
          .then((data) => {
            if (data.status === 200) {
              this.listOcorrencia(this.ocorrencia.pessoa.id);
              this.hideDialog();
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Ocorrência atualizada com sucesso.",
                life: 5000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    sendOcorrencia() {
      this.authService.checarToken(this.saveOcorrencia());
    },
    /** FUNÇÕES OCORRENCIA  FIM */
    /** FUNÇÕES HORARIO */
    openHorario() {
      this.horario.ocorrencia.id = this.selectedOcorrencia.id;
      this.titleDialog = "Jornada de Trabalho";
      this.horarioDialog = true;
    },
    openEditHorario(horario) {
      this.horario = horario;
      this.horario.inicio = this.horario.inicio.replace(
        "T00:00:00.000+00:00",
        ""
      );
      if (this.horario.fim !== null) {
        this.horario.fim = this.horario.fim.replace("T00:00:00.000+00:00", "");
      }
      this.titleDialog = "Jornada de Trabalho";
      this.horarioDialog = true;
    },
    openFinalizarHorario(horario) {
      this.horario = horario;
      this.horario.descricao = null;
      this.horario.fim = null;
      this.titleDialog = "Encerrar Jornada de Trabalho";
      this.horarioFinalizarDialog = true;
    },
    openDeleteHorarioDialog(horario) {
      this.horario = horario;
      this.horarioDeleteDialog = true;
    },
    listHorario(idOcorrencia) {
      this.selectedOcorrencia = { id: idOcorrencia };
      this.horarioService.listarPorOcorrencia(idOcorrencia).then((data) => {
        this.horarios = data;
      });
      this.btnNewHorario = false;
    },
    saveHorario() {
      this.submitted = true;
      if (this.horario.id == null) {
        this.horarioService
          .criar(this.horario)
          .then((data) => {
            if (data.status === 201) {
              this.listHorario(this.horario.ocorrencia.id);
              this.hideDialog();
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Horario cadastrado com sucesso!",
                life: 5000,
              });
            }
          })
          .catch((error) => {
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;

            this.msgDesenvolvedor = this.msgDesenvolvedor.replace(
              "br.gov.pa.saude.api.exceptionhandler.NegocioException:",
              ""
            );
            alert(this.msgUsuario + ". " + this.msgDesenvolvedor);
          });
      } else {
        this.horarioService
          .atualizar(this.horario.id, this.horario)
          .then((data) => {
            if (data.status === 200) {
              this.listHorario(this.horario.ocorrencia.id);
              this.hideDialog();
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Horario atualizado com sucesso.",
                life: 5000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    sendHorario() {
      this.authService.checarToken(this.saveHorario());
    },
    deleteHorario() {
      this.horarioService.remover(this.horario.id).then((data) => {
        if (data.status === 204) {
          this.listHorario(this.horario.ocorrencia.id);
          this.hideDialog();
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Horario deletado com sucesso.",
            life: 5000,
          });
        } else {
          this.$toast.add({
            severity: "erro",
            summary: "Erro!",
            detail: "Erro ao deletar registro.",
            life: 5000,
          });
        }
      });
    },
    sendDeleteHorario() {
      this.authService.checarToken(this.deleteHorario());
    },
    verificarEncerrado(dados) {
      if (dados === null) {
        return false;
      } else {
        return true;
      }
    },
    /** FUNÇÕES HORARIO FIM*/
    imprimirQRCode() {
      window.print();
      /* var dados = document.getelementById("cartaoQRCODE").innerHTML;
      dados.print();*/
      /*const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [3, 4],
      });

      doc.save();
      */
    },
    verificarDisponibilidadeQrCode(dados) {
      if (dados === "NÃO INFORMADO" || dados === "LEITOR BIOMETRICO") {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-dialog .setor-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setor-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media print {
  .print {
    display: none;
  }
  .return {
    display: none;
  }
}

@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
.p-success {
  border-color: #256029;
}

.p-info {
  border-color: #0d89ec;
}

.product-badge {
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.verde {
  background: #c8e6c9;
  color: #256029;
}

.vermelho {
  background: #ffcdd2;
  color: #c63737;
}

.amarelo {
  background: #feedaf;
  color: #8a5340;
}

.preto {
  background: #c0c0c0;
  color: #000000;
}
</style>
