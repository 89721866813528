import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class SetorService extends AuthService {


  /* LISTAR TODOS */
  listar() {
    return axios({
      method: 'get',
      url: this.api_url + 'setor',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  /* CADASTRAR */
  criar(setor) {
    return axios({
      method: 'post',
      url: this.api_url + 'setor',
      withCredentials: true,
      data: setor,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* REMOVER */
  remover(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'setor/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* ATUALIZAR */
  atualizar(id, setor) {
    return axios({
      method: 'put',
      url: this.api_url + 'setor/' + id,
      withCredentials: true,
      data: setor,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
}
